@import url('https://fonts.googleapis.com/css?family=Open+Sans:600|Work+Sans:400,500,600,700&display=swap');

html {
    background: rgb(34, 193, 195);
    background: linear-gradient(135deg, rgb(243, 209, 171) 0%, rgb(193, 232, 239) 100%);
    font-family: "work sans";
    height: 100%;
    display: block;
}

.error {
    color: red;
    font-size: 22px;
    text-align: center;
}

.login-form {
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    min-height: 700px;
    width: 80%;
    height: 80%;
    margin: 100px auto;
    background-color: #FFFFFF;
    border-radius: 25px;
}

.form-link {
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    min-height: 700px;
    width: 300%;
    height: 100%;
    margin: 100px auto;
    align-content: center;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.forgottenPassword-form {
    background-color: rgba(217, 32, 145, 0.18);
    max-width: 500px;
    min-width: 300px;
    max-height: 200px;
    min-height: 400px;
    width: 300%;
    height: 50%;
    margin: 100px auto;
    border-radius: 25px;
}


.signUp-form {
    max-width: 500px;
    min-width: 300px;
    max-height: 700px;
    min-height: 700px;
    width: 300%;
    height: 100%;
    margin: 100px auto;
    background-color: rgba(234, 107, 107, 0.17);
    border-radius: 25px;
}

.title {
    text-align: center;
    font-family: 'open sans', sans-serif;
    padding: 2rem 0;
    margin: 0;
    font-size: 2rem;
}

.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2rem;
    max-width: 100%;

}

.input-container input {
    width: 80%;
    box-sizing: border-box;
    border: none;
    font-size: 1.3rem;
    padding-left: 1.5rem;
    padding-bottom: 1rem;
    box-shadow: inset 0 -3px 0px 0px rgba(187, 187, 187, 0.2);
    transition: box-shadow 0.2s ease-in;
}

.input-container input:focus {
    box-shadow: inset 0px -3px 0px 0px rgba(34, 193, 195, 0.7);
    outline: none;
}

.input-container input::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.25s ease-out;
}

.input-container input:hover::-webkit-input-placeholder,
.input-container input:focus::-webkit-input-placeholder {
    opacity: 0;
}

.input-container label {
    align-self: start;
    padding-left: 4.5rem;
    padding-bottom: 0.5rem;
    color: rgb(155, 150, 150);
}

.button-container input {
    display: block;
    margin: 50px auto;
    border-radius: 25px;
    width: 80%;
    height: 40px;
    font-size: 1.3rem;
    color: white;
    font-weight: 700;
    background: rgb(34, 193, 195);
    background: linear-gradient(90deg, rgba(34, 193, 195, 1) 0%, rgba(253, 187, 45, 1) 100%);
    border: 0;
    padding: 7px;
    cursor: pointer;
    text-align: center;
    vertical-align: center;
    transition: opacity 0.25s ease-out;
}

.button-container input:hover {
    opacity: 50%;
    background: linear-gradient(90deg, rgb(254, 165, 0) 0%, rgb(45, 142, 253) 100%);
    font-size: 1.4rem;
}

.button-container input {
    padding-bottom: 1.5rem;
}

#facebookIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145802.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
}

#twitterIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145812.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
}

#googleIcon {
    background-image: url("https://image.flaticon.com/icons/svg/145/145804.svg");
    background-repeat: no-repeat;
    width: 3rem;
    height: 3rem;
}

#iconGroup {
    display: flex;
    justify-content: center;
    padding: 2rem 0;
}

#iconGroup a {
    margin: 0 0.6rem;
}

#alternativeLogin {
    text-align: center;
    padding-top: 2rem;
    margin-top: 1.5rem;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}


@media only screen and (max-width: 1110px) {



}
