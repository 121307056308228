.header {
    overflow: hidden;
    padding: 20px 20px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

/* Style the header links */
.header a {
    float: left;
    color: black;
    text-align: center;
    padding: 12px;
    text-decoration: none;
    font-size: 18px;
    line-height: 25px;
    border-radius: 4px;
}

/* Style the logo link (notice that we set the same value of line-height and font-size to prevent the header to increase when the font gets bigger */
.header a.logo {
    font-size: 25px;
    font-weight: bold;
}

/* Change the background color on mouse-over */
.header a:hover {
    background-color: #ddd;
    color: black;
}

/* Style the active/current link*/
.header a.active {
    background-color: dodgerblue;
    color: white;
}

/* Float the link section to the right */
.header .header-right {
    float: right;
    position: relative;
    top: 30%;
    bottom: 100%;
}



.logo{
    width: auto;
    height: auto;
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    .logo {
        top: 50%;
        left: 50%;
        transform: translate(-50%);
    }
    .header{
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
    }
    .header-right {
        align-items: center;
        width: 90%;
        padding-left: 5%;
        padding-right: 5%;
        float: right;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        justify-content: space-around;


    }
    .header-right a{
        justify-content: space-between;
        width: 15%;

    }
}
