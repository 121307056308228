
html {
    background: linear-gradient(135deg, rgb(239, 204, 204) 0%, rgb(252, 192, 192) 100%);
    font-family: "Merriweather", "serif";
}

.section {
    min-height: 500px;
    max-height: 9000px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
}

.text-section {
    width: 30%;
    text-align: center;
    vertical-align: middle;
    height: 100%;
    min-height: 500px;
    max-height: 600px;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
    float: left; /* add this */
    padding-left: 10%;
    padding-right: 10%;
    font-size: 22px;
}

.image-section {
    width: 50%;
    text-align: center;
    vertical-align: middle;
    float: left; /* add this */
    height: 100%;
    max-height: 600px;
    min-height: 500px;
    align-content: center;
    display: flex;
    flex-wrap: wrap;
}

img {
    margin-left: auto;
    margin-right: auto;
    border-radius: 25px;
}

h1 {
    margin-left: auto;
    padding: 0;
    margin-right: auto;
    border-radius: 25px;
    font-family: Graphik web, sans-serif;
}

#signUp-button {
    border: 0;
    margin: auto;
    flex-wrap: inherit;
    border-radius: 4px;
    font-weight: 600;
    display: block;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    color: white;
    background-color: rgba(104, 85, 224, 1);
}

@media only screen and (max-width: 1110px) {

    .text-section{
        font-size: 20px;
        padding-left: 10%;
        padding-right: 10%;
        width: 80%;
    }

    .image-section {
       display: none;
    }

}
