

table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    font-size: 0.9em;
    min-width: 400px;
    width: 75%;
    align-content: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    border-radius: 60px;
    margin-top: 5%;
    margin-left: auto;
    margin-right: auto;
}

table tr:last-child td:first-child {
    border-bottom-left-radius: 20px;
}

table tr:last-child td:last-child {
    border-bottom-right-radius: 20px;
}

th:first-child {
    border-top-left-radius: 20px;
}

th:last-child {
    border-top-right-radius: 20px;
}


td, th {
    border: 1px solid #ddd;
    padding: 8px;
    align-content: center;
}

tr:nth-child(odd) {
    background-color: #ec9370;

}

tr:nth-child(even) {
    background-color: #ecbfad;
}

tr:hover td {
    background-color: rgb(255, 148, 177);
}

th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #c2fff6;
}

#add-item-button {
    background-color: rgb(142, 159, 232); /* Green */
    color: white;

    text-align: center;
    display: block;
    width: 150px;
    margin: 0 auto;
}

#empty-list {
    font-size: 29px;
    align-content: center;
}

#dashboard {
    padding: 10px;
    text-align: center;
}

.btn {
    background-color: pink;
    border: none;
    border-radius: 20px;
    color: red;
    padding: 12px 16px;
    font-size: 16px;
    cursor: pointer;
}

.btn:hover{
    background-color: red;
    color: white;
}